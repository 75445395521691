

.input {
    margin-bottom: 16px;
}

.label {
    display: flex;
    justify-content: space-between;
    >label {
        width: 48%;
    }
}

.title {
    display: flex;
    margin: 40px 0 24px;
    color: #151724;
    font-family: Inter;
    font-weight: 500;
}

.justify_center_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 50%;
        margin-right: 16px;
    }
    >div:last-child {
        width: 50%;
        height: 70px;
    }
}

.section_title {
    display: flex;
    margin: 24px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #151724;
}

.bottom_line {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    margin-bottom: 39px;
    margin-top: 39px;
    line-height: 24px;
    color: #7e858f;
    height: 16px;
    border-top: 1px solid #bcc4cc;
}

.bottom_line_open {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 39px;
    line-height: 24px;
    color: #7e858f;
    height: 16px;
    border-top: 1px solid #bcc4cc;
}