.bordered {
  border: 2px solid #151724;
}

.select_label {
    background: #f6f7f8;
    border-radius: 5px;
    padding: 32px 13px 0;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

  .country_select {
    img {
      width: 20px;
      margin-right: 10px;
    }
    div {
      border: none;
      box-shadow: unset;
      
      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(212, 209, 209);
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #b2b3b4;
        border-radius: 10px;
      }
      span {
        display: none;
      }
    }
  }

    > select {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        background: #f6f7f8;
        border: none;
        border-radius: 5px;
        outline: none;
        margin-left: 4px;
    }

    > select::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #151724;
    }
}
