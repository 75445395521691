.text_input_label {
    background: #f6f7f8;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 13px;
    .input_placeholder {
      position: absolute;
      top: 50%;
      left: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #7E858F;
    }
    .input_placeholder_not_empty {
      position: absolute;
      top: 10%;
      left: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: rgba(86, 97, 113, 0.6);
    }
    > div {
        padding: 36px 3px 10px;
        display: flex;
        align-items: flex-end;
    }

    > div > input {
      border: unset;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      background: transparent;
      color: #151724;
      outline: none;
      width: 100%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }

    > div > input::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #7e858f
    }
    @media screen and (max-width: 461px) {
      .input_placeholder {
        font-size: 14px;
      }
      .input_placeholder_not_empty {
        left: 11px;
      }
    }
    @media screen and (max-width: 374px) {
      .input_placeholder {
        font-size: 14px;
        top: 30%;
      }
    }
  }