.flexContainer {
  position: relative;
  margin: 40px 0 26px;
  width: 100%;
  display: flex;
  align-items: center;
}

.validator_error_select_style {
  border: 1px solid red !important;
}

.validator_text {
  position: absolute;
  top: 50px;
  color: red;
  font-size: 12px;
}


.select_label {
  border-radius: 5px;
  border: 1px solid rgba(21, 23, 36, 0.3);
  padding: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;

  .country_select {
    div {
      border: none;
      box-shadow: unset;
    }
    span {
      display: none;
    }
  }

  > select {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: #f6f7f8;
    border: none;
    border-radius: 5px;
    outline: none;
  }

  > select::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7e858f
  }
}

.add_owner {
  width: 70px;
  margin-left: 16px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(21, 23, 36, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 10px 12px;
  color: #151724;
  cursor: pointer;
  &:hover {
    border: 1px solid #151724;
  }
  &:focus {
    border: 1px solid #151724
  }
  &:active {
    border: 1px solid rgba(21, 23, 36, 0.8)
  }
  &:disabled {
    background: #F6F7F8;
    border: 1px solid rgba(21, 23, 36, 0.2);
    color: rgba(21, 23, 36, 0.5);
  }
  .loading_span {
    margin-right: 16px;
  }
}