
.select_label {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 196, 204, 0.5);

  .date_placeholder_not_empty {
    position: absolute;
    top: 8%;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #151724;
    z-index: 1;
  }

  .country_select {
    padding: 11px 0 1px;
    display: flex;
    flex-direction: column;
    color: rgba(86, 97, 113, 0.6);
    justify-content: flex-end;
    > div {
      align-items: flex-end;
    }

    img {
      width: 20px;
      margin-right: 10px;
    }
    div {
      border: none;
      span {
        background-color: unset;
      }
    }
  }
}