.main {
    position: relative;
}

.input {
    margin-bottom: 16px;
}

.uneven_fields_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 65%;
        margin-right: 16px;
        @media screen and (max-width: 600px) {
            width: 60%;
        }
        @media screen and (max-width: 600px) {
            width: 50%;
        }
        @media screen and (max-width: 462px) {
            width: 100%;
            margin-bottom: 16px;
            margin-right: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }
    >div:last-child {
        width: 35%;
        @media screen and (max-width: 600px) {
            width: 40%;
        }
        @media screen and (max-width: 600px) {
            width: 50%;
        }
        @media screen and (max-width: 462px) {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
        }
    }
    .license_issuing_date {
        input {
            width: 100%;
        }
    }
    .label_inside {
        input {
            width: 100%;
            @media screen and (max-width: 374px) {
                padding-left: 14px;
            }
        }
    }
    @media screen and (max-width: 462px) {
        flex-direction: column;
    }
}

.display_flex_section {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;
    align-items: flex-start;
    >div:first-child {
        width: 79%;
        margin-right: 16px;
        @media screen and (max-width: 475px) {
            width: 65%;
        }
    }
    >div:last-child {
        width: 21%;
        height: 70px;
        @media screen and (max-width: 560px) {
            width: 35%;
        }
    }
}

.display_flex_reverse_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 24%;
        margin-right: 16px;
        @media screen and (max-width: 698px) {
            width: 35%;
        }
        @media screen and (max-width: 500px) {
            width: 50%;
        }
        @media screen and (max-width: 374px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
            div {
                padding-left: 3px;
            }
        }
    }
    >div:last-child {
        width: 76%;
        height: 70px;
        @media screen and (max-width: 698px) {
            width: 65%;
        }
        @media screen and (max-width: 500px) {
            width: 50%;
        }
        @media screen and (max-width: 374px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
    @media screen and (max-width: 374px) {
        width: 100%;
        flex-direction: column;
    }
}

.justify_center_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 50%;
        margin-right: 16px;
    @media screen and (max-width: 475px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        padding-left: 0;
        padding-right: 0;
    }
    }
    >div:last-child {
        width: 50%;
        height: 70px;
    @media screen and (max-width: 475px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    }
    @media screen and (max-width: 475px) {
        flex-direction: column;
    }
}

.section_title {
    display: flex;
    margin: 24px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #151724;
}

.some_text_styles {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #7E858F;
}

.bottom_line {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    margin-bottom: 54px;
    line-height: 24px;
    color: #7e858f;
    border-bottom: 1px solid #bcc4cc;
}

.companies_div {
    display: flex;
    flex-direction: column;
    margin: 16px 0 13px;
    background: #ffffff;
    border-radius: 5px;
    max-height: 312px;
    overflow-y: auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
}

.company_button {
    border: none;
    background: transparent;
    text-align: left;
    padding: 12px 16px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    font-family: Inter;
    cursor: pointer;
}

.company_button:hover{
    background: #F6F7F8;
}