

.textarea_label {
    background: #f6f7f8;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px 4px 0;

  .textarea_placeholder {
    position: absolute;
    top: 53%;
    left: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7E858F;
  }
  .textarea_placeholder_not_empty {
    position: absolute;
    top: 10px;
    left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(86, 97, 113, 0.6);
  }

    textarea {
        width: 98%;
        resize: vertical;
        height: 45px;
        display: flex;
        align-items: flex-end;
        border: unset;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        background: transparent;
        color: #151724;
        outline: none;
        padding-left: 14px;
    }

   textarea::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #7e858f;
        padding-top: 12px;
    }

    textarea:focus::placeholder {
        color: transparent
    }
}
