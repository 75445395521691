
.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.dialog_wrapper {
  background: white;
  width: 450px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid rgba(21, 23, 36, 0.3);
  border-radius: 10px;
  padding: 0 30px;
}

.description {
  display: flex;
  justify-content: center;
  color: black;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 700;
}

.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  button {
    padding: 5px 20px;
    background: #ffffff;
    border: 1px solid rgba(21, 23, 36, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #151724;
  }
  button:first-child {
    margin-right: 30px;
  }
}

.button {
  background: #ffffff;
  border: 1px solid rgba(21, 23, 36, 0.3) !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  color: #151724;
  cursor: pointer;
  width: 100px;
  :hover {
    border: 1px solid #151724 !important;
  }
  :focus {
    border: 2px solid #151724 !important;
  }
}

.textarea {
  padding: 30px 4px 0 !important;
  textarea {
    min-height: 100px !important;
  }
}