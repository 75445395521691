body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0
}

.onboarding_form {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 80px 0 120px
}

@media screen and (max-width: 1200px) {
    .onboarding_form {
        width: calc(100% - 30px);
        margin: auto;
    }
}

.onboarding_form
.border_bottom {
    border-bottom: unset !important
}

.onboarding_form
.bottom_space >
h4 {
    margin-bottom: 24px;
    margin-top: 40px
}

.onboarding_form
.sources_benefic {
    margin-top: 40px
}

.onboarding_form
.sources_benefic h4 {
    margin-bottom: 24px
}

.onboarding_form
.sources_benefic > div {
    display: flex;
    flex-wrap: wrap
}

.onboarding_form
.sources_benefic >
div > button {
    background: #ffffff;
    border: 1px solid rgba(21, 23, 36, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    padding: 12px 24px;
    color: #151724;
    margin-right: 16px;
    margin-bottom: 16px;

}

.onboarding_form .radio_section .no_border {
    border: unset !important
}

.onboarding_form .radio_section form select {
    margin-bottom: 0
}

.onboarding_form .radio_section form > label {
    margin-bottom: 16px
}

.onboarding_form .benefic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 40px;
    background: #ffffff;
    border: 1px solid rgba(188, 196, 204, 0.6);
    border-radius: 5px
}

.onboarding_form .benefic .add_owner {
    background: #ffffff;
    border: 1px solid rgba(21, 23, 36, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    margin-top: 64px;
    padding: 10px 12px;
    color: #151724
}

.onboarding_form .benefic .checkbox_component {
    margin-top: 0
}

.onboarding_form .benefic .checkbox_component.margin_none > div {
    margin-bottom: 0
}

.onboarding_form .benefic .checkbox_component > div {
    flex-wrap: wrap
}

.onboarding_form .benefic .checkbox_component > div label:not(:last-child) {
    margin-bottom: 32px
}

.onboarding_form .benefic .select_box {
    border: none !important;
    width: 100%;
    padding-bottom: 32px
}

.onboarding_form .benefic .select_box p > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #7e858f;
    padding-left: 24px
}

.onboarding_form .benefic label div, .onboarding_form .benefic label select {
    background-color: unset;
    border-bottom: 2px solid #bcc4cc;
    border-radius: unset;
    margin-bottom: 16px;
    padding-top: 0;
    padding-left: 0
}


.onboarding_form .select_title {
    position: absolute;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    left: 16px;
    top: 10px;
    color: rgba(86, 97, 113, 0.6)
}

.onboarding_form .shareholders .select_box p > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #7e858f;
    padding-left: 20px
}

.onboarding_form .shareholders.managment {
    margin-top: 120px
}

.onboarding_form .shareholders.managment .mb-2 {
    margin-bottom: 40px
}

.onboarding_form .shareholders.managment .select_box p > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    padding-left: 26px;
    color: #7e858f
}



.onboarding_form .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 16px
}

.onboarding_form .row div {
    margin: 0 !important
}

.onboarding_form .upload_doc_fill {
    width: 100%;
    height: 100%;
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: solid 1px #bcc4cc
}

.onboarding_form .upload_doc_fill .file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 16px;
    margin-top: 16px;
    border: 1px solid #eff1f2;
    box-sizing: border-box;
    border-radius: 5px
}

.onboarding_form .upload_doc_fill > label {
    margin-top: 16px
}

.onboarding_form .upload_doc_fill > h4 {
    margin: 40px 0 24px
}

.onboarding_form .upload_doc_fill > ul li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    list-style-position: inside;
    color: #7e858f
}

.onboarding_form .upload_doc_fill > .doted_box {
    background: #f6f7f8;
    border: 2px dashed #566171;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    flex-direction: column;
    padding: 24px
}

.onboarding_form .upload_doc_fill > .doted_box > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #151724;
    margin-bottom: 8px
}

.onboarding_form .upload_doc_fill > .doted_box > span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #7e858f;
    text-align: center;
    display: block;
    margin-top: 16px
}

.onboarding_form .upload_doc_fill > .doted_box label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #151724;
    display: block;
    margin: 0 auto;
    background: #ffffff;
    padding: 12px 10px;
    max-width: 145px;
    margin: 0 auto;
    border: 1px solid rgba(21, 23, 36, 0.5);
    box-sizing: border-box;
    border-radius: 6px
}

.onboarding_form .upload_doc_fill > .doted_box label > input {
    display: none
}

.onboarding_form .bussines_info > h4 {
    margin: 40px 0 24px
}

.onboarding_form .radio_component h4 {
    display: flex
}

.onboarding_form .radio_component h4 p {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgba(86, 97, 113, 0.6)
}

.onboarding_form .radio_component .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #bcc4cc;
    box-sizing: border-box;
    border-radius: 50%;
    width: 24px;
    height: 24px
}

.onboarding_form .radio_component.changed_style > div {
    margin-bottom: 16px
}

.onboarding_form .checkbox_component .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #bcc4cc;
    box-sizing: border-box;
    width: 24px;
    border-radius: 5px;
    height: 24px
}

.onboarding_form .checkbox_component, .onboarding_form .radio_component {
    margin-top: 24px
}

.onboarding_form .checkbox_component > div, .onboarding_form .radio_component > div > div {
    margin-top: 7px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
}

.onboarding_form .checkbox_component > div > label, .onboarding_form .radio_component > div > label:nth-child(1) {
    margin-right: 30px
}

@media screen and (max-width: 414px) {
    .onboarding_form .checkbox_component > div > label, .onboarding_form .radio_component > div > label:nth-child(1) {
        margin-right: 0;
    }
}

.onboarding_form .checkbox_component .container, .onboarding_form .radio_component .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-right: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #566171;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none
}

@media screen and (max-width: 374px) {
    .onboarding_form .checkbox_component .container, .onboarding_form .radio_component .container {
        padding-left: 25px;
        font-size: 15px;
    }
}

.onboarding_form .checkbox_component .container input, .onboarding_form .radio_component .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.onboarding_form .checkbox_component .container:checked input ~ .checkmark, .onboarding_form .radio_component .container:checked input ~ .checkmark {
    border: 2px solid #151724
}

.onboarding_form .checkbox_component .container ~ .checkmark, .onboarding_form .radio_component .container ~ .validator_error_style_radio {
    border: 2px solid red
}

.onboarding_form .checkbox_component .container:hover input ~ .checkmark, .onboarding_form .radio_component .container:hover input ~ .checkmark {
    border: 1px solid #566171
}

.checkbox_component .container input:checked ~ .checkmark, .radio_component .container input:checked ~ .checkmark {
    background: #151724
}

.onboarding_form .checkbox_component .checkmark:after, .onboarding_form .radio_component .checkmark:after {
    content: url("./pages/images/checkmark.svg");
    position: absolute;
    display: none
}

.onboarding_form .checkbox_component .container input:checked ~ .checkmark:after, .onboarding_form .radio_component .container input:checked ~ .checkmark:after {
    display: block
}

.onboarding_form .checkbox_component .container .checkmark:after, .onboarding_form .radio_component .container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}


.onboarding_form label.register_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 16px
}

.onboarding_form label.register_date > div {
    width: 100%;
    margin: 0 !important
}

.onboarding_form label.register_date > div:last-child {
    max-width: 224px;
    margin-left: 16px !important
}

.onboarding_form label.grid_inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.grid_inputs_label {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}
.grid_inputs_label label {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.grid_inputs_label label label {
    margin-bottom: unset;
    height: unset;
}

.onboarding_form label.grid_inputs > div {
    margin-bottom: 0 !important
}


.onboarding_form h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 45px;
    letter-spacing: -0.03em;
    color: #151724;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 2px ;
    padding: 4px 0;
}

@media screen and (max-width: 380px) {
    .onboarding_form h2 {
        font-size: 40px;
    }
}

@media screen and (max-width: 380px) {
    .onboarding_form h2 {
        font-size: 35px;
    }
}

.onboarding_form h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #151724;
    margin-bottom: 40px;
    position: sticky;
    top: 45px;
    background: #fff;
    padding: 5px 0;
    z-index: 998;
}

.onboarding_form h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #151724
}

.onboarding_form h4.hide {
    margin: 40px 0 20px
}

.onboarding_form > .content {
    max-width: 720px;
    width: 100%;
    height: 100%;
    margin: 0 auto
}

#shareholder > div {
    box-shadow: unset;
    padding: 0;
}

#manager > div {
    box-shadow: unset;
    padding: 0;
}

.dropzone {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.MuiAccordionSummary-root {
    padding: 0 !important;
}
.MuiAccordionDetails-root {
    display: unset !important;
    padding: 0 !important;
}
.MuiAccordion-root {
    border-bottom: 1px solid #BCC4CC;
    border-top: 1px solid #BCC4CC;
}

.MuiAccordion-rounded {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.MuiAccordion-root.Mui-expanded {
    margin-top: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 16px 0 !important;
}

.absolute {
    position: fixed;
    top: 0;
    z-index: 99;
}

.checked:after {
    content: url("./pages/images/checkmark.svg");
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.checked {
    background: #151724 url("./pages/images/checkmark.svg") no-repeat center;
    border: 2px solid #151724
}

.react-datepicker-popper { z-index: 99 !important }
