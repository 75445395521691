.btnOff {
    border-color: #e6e6e6 !important;
}
.btnOn {
    border-color: #333 !important;
}
.sources_benefic {
    margin-top: 40px;
    > div {
      display: flex;
      flex-direction: column;
      >label {
        margin: 0 0 24px !important;
        border-bottom: unset !important;
      }
    }
}

.section_title {
  display: flex;
  margin: 24px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}