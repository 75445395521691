.main {
    height: 100%;
    margin: 0 auto;
    max-width: 720px;
    padding-top: 80px;
    @media screen and (max-width: 414px) {
        padding-top: 40px;
    }
}

.title {
    font-size: 45px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: -0.03em;
    margin: 0;
    color: #151724;
    text-align: center;
    font-family: Montserrat;
    @media screen and (max-width: 414px) {
        font-size: 38px;
    }
}

.msg {
    line-height: 24px;
    color: #7E858F;
    margin: 24px 0;
    text-align: center;
    font-family: Inter;
}

.wrapper {
    text-align: center;
}

.btn {
    padding: 12px 16px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #7E858F;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: Inter;
    text-decoration: none;
    color: #151724;
}

.imgdiv {
    margin: 107px auto;
    text-align: center;
    width: 422px;
    height: 369px;
    svg {
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 422px) {
        margin: 24px auto;
        height: 280px;
        width: 320px;
    }
}