.text_input_label {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 196, 204,0.5);
  padding: 21px 0 5px;
  .input_placeholder {
    position: absolute;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7E858F;
  }
  .input_placeholder_not_empty {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(86, 97, 113, 0.6);
    top: 0;
  }
  > div {
    display: flex;
    align-items: flex-end;
  }

  > div > input {
    border: unset;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: transparent;
    color: #151724;
    outline: none;
    width: 100%;
  }

  > div > input::-webkit-outer-spin-button,   
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  > div > input::placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7e858f
  }
  @media screen and (max-width: 461px) {
    .input_placeholder {
      font-size: 14px;
    }
    .input_placeholder_not_empty {
      left: 11px;
    }
  }
  @media screen and (max-width: 374px) {
    .input_placeholder {
      font-size: 14px;
      top: 40%;
    }
  }
}
