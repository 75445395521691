.shareholder {
    p {
      width: 100%;
    }
  .radio_wrapper {
    margin-top: 36px !important;
    margin-bottom: 40px !important;
    display: flex;
  }
    .select_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      position: sticky;
      top: 55px;
      background: #fff;
      padding: 5px 0;
      z-index: 998;
      > div {
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #151724;
          margin: 0 16px;
          display: flex;
        }
      }
      .left_side {
        display: flex;
        align-items: center;
      }
      .right_side {
        display: flex;
        .percent {
          align-items: center;
        }
      }
    }
  .add_additional_button {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: #FFFFFF;
    border: 1px solid rgba(21, 23, 36, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
        border: 1px solid #151724;
    }
    &:focus {
      border: 2px solid #151724
    }
    &:active {
        border: 1px solid rgba(21, 23, 36, 0.8)
    }
    &:disabled {
        background: #F6F7F8;
        border: 1px solid rgba(21, 23, 36, 0.2);
        color: rgba(21, 23, 36, 0.5);
    }
  }
  .accordion_title_text {
    display: flex;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    align-items: center;
    color: #7E858F;
    margin-right: 40px;
  }
  @media screen and (max-width: 500px) {
    .accordion_title_text {
      margin-right: 0;
    }
  }
}

.display_flex_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  >div:first-child {
    width: 70%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 30%;
  }
}

.justify_center_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  >div:first-child {
    width: 50%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
  }
}
.justify_center_section_person {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  >div:first-child {
    width: 51%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
}


.display_flex_reverse_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  >div:first-child {
    width: 30%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 70%;
    height: 70px;
  }
}

@media screen and (max-width: 650px) {
  .display_flex_reverse_section {
    >div:first-child {
      width: 40%;
    }
    >div:last-child {
      width: 60%;
    }
  }
}

@media screen and (max-width: 458px) {
  .display_flex_reverse_section {
    >div:first-child {
      width: 50%;
    }
    >div:last-child {
      width: 50%;
    }
  }
}

@media screen and (max-width: 500px) {
  .select_box {
    .right_side {
      flex-direction: column-reverse;
    }
  }
  .display_flex_section {
    >div:first-child {
      width: 60%;
    }
    >div:last-child {
      width: 40%;
    }
  }
  .justify_center_section, .justify_center_section_person {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    >div:last-child {
      width: 100%;
    }
  }
}

@media screen and (max-width: 374px) {
  .display_flex_reverse_section, .display_flex_section {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    >div:last-child {
      width: 100%;
    }
  }
}

.section_title {
  display: flex;
  margin: 24px 0 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}

.section_title_top {
  display: flex;
  margin: 40px 0 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}

.section_title_bottom {
  display: flex;
  margin: -24px 0 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}

.add_owner {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(21, 23, 36, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  margin: 0 0px 26px;
  padding: 10px 12px;
  color: #151724;
  cursor: pointer;
  &:hover {
    border: 1px solid #151724;
  }
  &:focus {
    border: 2px solid #151724
  }
  &:active {
    border: 1px solid rgba(21, 23, 36, 0.8)
  }
  &:disabled {
    background: #F6F7F8;
    border: 1px solid rgba(21, 23, 36, 0.2);
    color: rgba(21, 23, 36, 0.5);
  }
  .loading_span {
    margin-right: 16px;
  }
}
