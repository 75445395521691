.ubo_title {
  font-size: 16px;
  line-height: 24px;
  color: #151724;
  font-family: "Inter";
  font-weight: 500;
}
.beneficial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 52px 40px 0;
  background: #ffffff;
  border: 1px solid rgba(188, 196, 204, 0.6);
  border-radius: 5px;
  margin-top: 24px;
  margin-bottom: 20px;

  form {
    width: 100%;
    margin-bottom: -16px;
  }
  .background_select select{
    background: white !important;
    padding-left: 9px;
  }
  .background_select {
    padding: 36px 2px 10px !important;
  }
  .radio_component {
    > div {
      margin-bottom: 0 !important;
    }
  }

  .background_white input {
    background: white !important;
  }
  .background_white {
    width: 98%;
    background: white !important;
    padding: 16px 8px 10px !important;

  }
  .add_owner {
    background: #ffffff;
    border: 1px solid rgba(21, 23, 36, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    margin-top: 64px;
    padding: 10px 12px;
    color: #151724;
    cursor: pointer;
    &:hover {
      border: 1px solid #151724;
    }
    &:focus {
      border: 2px solid #151724
    }
    &:active {
      border: 1px solid rgba(21, 23, 36, 0.8)
    }
    &:disabled {
      background: #F6F7F8;
      border: 1px solid rgba(21, 23, 36, 0.2);
      color: rgba(21, 23, 36, 0.5);
    }
  }
  >label {
    background: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(21, 23, 36, 0.5);
    width: 100%;
    padding-right: 0 !important;
    >label {
      > label {
        padding-right: 0;
      }
    }
  }

  select {
    background: none;
  }

  .currencies {
    .active {
      border: 1px solid #566171;
    }
    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      margin-bottom: 24px;
    }

    button {
      height: 50px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      outline: none;
      padding: 12px 10px;
      color: #151724;
      border: 1px solid #EFF1F2;
      box-sizing: border-box;
      border-radius: 6px;

      > img {
        margin-left: 13px
      }
    }
  }

  @media screen and (max-width: 768px) {
    .currencies > div {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 13px;
    }
  }
}
.beneficial_title {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 16px;
  .left_side {
    display: flex;
    img {
      width: 20px;
      height: 24px;
    }
    p {
      margin-left: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #151724;
    }
  }
  .right_side {
    display: flex;
    .percent {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      color: #151724;
    }
  }
  @media screen and (max-width: 522px) {
    .right_side {
      flex-direction: column-reverse;
    }
  }
  .deleteButton {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    align-items: center;
    color: #7E858F;
    margin-right: 56px;
    cursor: pointer;
  }
  @media screen and (max-width: 563px) {
    .deleteButton {
      margin-right: 30px;
    }
  }
  @media screen and (max-width: 522px) {
    .deleteButton {
      margin-right: 0;
    }
  }
}
.display_flex_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  >label:first-child {
    width: 70%;
    margin-right: 16px;
  }
  >label:last-child {
    width: 30%;
  }
}

.justify_center_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  >div:first-child {
    width: 50%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
  }
}
.justify_center_section_person {
  width: 100%;
  display: flex;
  align-items: flex-start;
  >div:first-child {
    width: 50%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
  }
}

@media screen and (max-width: 555px) {
  .beneficial {
    padding: 52px 30px 0;
  }
}
@media screen and (max-width: 444px) {
  .beneficial {
    padding: 52px 20px 0;
  }
  .beneficial_title {
    .left_side {
      p {
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 534px) {
  .justify_center_section {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
    }
    >div:last-child {
      width: 100%;
    }
  }
  .justify_center_section_person {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
    }
    >div:last-child {
      width: 100%;
    }
  }
}


.display_flex_reverse_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  >div:first-child {
    width: 50%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
  }
}

.beneficial_textarea {
  padding-top: 0 !important;
}

.radio_section {
  margin-bottom: 24px !important;
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  margin: 24px 0 40px !important;
  span {
    width: 24px !important;
    height: 24px !important;
  }
}

.section_title {
  display: flex;
  margin: 40px 0 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}

@media screen and (max-width: 374px) {
  .display_flex_reverse_section {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
    }
    >div:last-child {
      width: 100%;
    }
  }
}
