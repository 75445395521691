.date_label {
  height: 100%;
  background: #F6F7F8;
  border-radius: 5px;
  padding: 36px 16px 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .date_placeholder {
    position: absolute;
    top: 50%;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7E858F;
  }

  .date_placeholder_not_empty {
    position: absolute;
    top: 8%;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(86, 97, 113, 0.6);
  }

  > div > div > div > input{
      background: #F6F7F8;
      border: none;
      outline: none;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      padding-left: 0 !important;
    }

  > div > div > div > input::placeholder {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #7E858F
  }
}

.date_icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
}

.UBO_data_label {
  background: transparent;
  padding: 25px 0 1px;
  margin-bottom: 0;
  position: relative;
  border-bottom: 1px solid rgba(188, 196, 204,0.5);
  > div > div > div > input{
    background: transparent;
    border: none;
    outline: none;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.UBO_data_icon {
  top: 16px;
  right: 6px;
  cursor: pointer;
  position: absolute;
}