.article_side {
  position: fixed;
  left: 0;
  top: 50px;
  max-width: 360px;
  width: 100%;
  margin-top: -40px;
  padding: 64px;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    visibility: hidden;
  }
  @media screen and (max-width: 1300px) {
    z-index: 1000;
  }

  .closeButtonContainer {
    display: none;
  }
  div {
    .title {
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      margin: 2px 0;
      font-size: 13px;
      color: #7E858F;
      font-family: Inter;
    }

    .uboTitle {
      padding: 10px 16px;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      margin: 2px 0;
      font-size: 13px;
      color: #7E858F;
      font-family: Inter;
    }

    .active {
      background: #F2F4F5;
      .activeSpan {
        color: #151724 !important;
      }
    }

    .title:hover {
      background: #F2F4F5;
    }
  }

  .ubo {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    .uboSpan {
      color: #7E858F;
      z-index: 10;
    }
    .uboSuccess {
      width: 8px;
      min-width: 8px;
      height: 8px;
      background-color: #2BB673;
      border-radius: 50%;
      margin-right: 16px;
    }
    .uboNotFill {
      width: 8px;
      min-width: 8px;
      height: 8px;
      background-color: #B2B6BC;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .management, .shareholders, .questionnaire {
      margin-bottom: 31px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(188, 196, 204, 0.5);
      >div {
        > a {
        display: flex;
        > span {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          margin-left: 16px;
          color: #7E858F;
        }
        .success {
          margin-left: 0;
          width: 20px;
          height: 20px;
          min-width: 20px;
        }
      }
      }
      > h4 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #151724;
        margin-bottom: 8px;
        border-radius: 8px;
        cursor: pointer;
        > a {
          width: 100%;
          height: 100%;
          padding: 8px;
          border-radius: 8px;
        }
      }
    }
  }

  .round {
    font-family: Inter;
    font-style: normal;
    width: 20px;
    height: 20px;
    min-width: 20px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b2b6bc;
    position: relative;
    border: 1px solid #b2b6bc;
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center
  }

  .start {
    margin-bottom: 24px;
    border-bottom: solid 1px rgba(188, 196, 204, 0.5);
  }
  a {
    color: #151724;
    text-decoration: none;
  }
  h2 {
    padding: 8px 16px;
    background: #f2f4f5;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    max-width: 240px;
    line-height: 24px;
    color: #151724;
    margin-bottom: 24px
  }
  .note {
    font-size: 14px;
    font-weight: 400;
    font-family: Inter;
    color: #7E858F;
    font-size: 13px;
    margin-top: 40px;
    line-height: 21px;
  }
  .steps {
    display: none;
  }
}

@media screen and (max-width: 1375px) {
  .article_side {
    padding-left: 35px;
  }
}
@media screen and (max-width: 1300px) {
   .article_side {
    width: unset;
    padding: 0%;
    .content {
      position: fixed;
      transition: all 0.2s ease;
      left: -100%;
    }

     .steps {
       display: block;
       cursor: pointer;
     }
  }

@media screen and (max-width: 1300px) {
  .article_side {
    > .steps {
      position: absolute;
      left: 0;
      bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 16px;
      background: #f2f4f5;
      border-radius: 0px 5px 5px 0px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #7e858f;
      z-index: 999;
      > img {
        margin-left: 10px
      }
    }
    .open {
      left: 0;
      z-index: 20000000;
      width: 230px;
      background: #fff;
      transition: all 0.2s ease;
      top: 0;
      bottom: 0;
      padding: 0 20px;
    }
    .closeButtonContainer {
      display: block;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 20px 0;
      .closeButton {
        cursor: pointer;
        margin-right: 10px;
        font-size: 12px;
        color: #7e858f;
      }
    }
  }
}
}
