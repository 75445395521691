.validator_error_style {
  border-bottom: 1px solid red;
}
.bordered {
  border: 2px solid #151724;
}
.validator_text {
    color: red;
    font-size: 12px;
}
.select_label {
    background: #f6f7f8;
    border-radius: 5px;
    padding: 32px 13px 0;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

  .country_select {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(212, 209, 209);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b2b3b4;
      border-radius: 10px;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
    div {
      border: none;
      box-shadow: unset;
      span {
        background-color: unset;
      }
    }
  }


    > select {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        background: #f6f7f8;
        border: none;
        border-radius: 5px;
        outline: none;
    }

    > select::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #7e858f
    }
}