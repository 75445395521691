.spinner {
    width: 20px;
    height: 20px;
    > img {
        width: 100%;
        height: 100%;
    }
    animation-name: spin;
    -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

