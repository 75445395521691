@media screen and (max-width: 768px) {
  .start_doc {
    padding: 16px
  }
}

.h2 {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 5px 0;
}


.start_doc {
  background: #f6f7f8;
  border-radius: 8px;
  padding: 32px;
  margin-top: 48px;
  position: relative;
  >  span {
    font-family: Inter;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #7e858f;
    margin-bottom: 16px;
    > span {
      color: black
    }
  }
  h4 {
    margin: 24px 0 16px
  }
  a {
    color: black;
    cursor: pointer;
  }
  ol {
    margin: 16px 0;
    li {
      list-style-position: inside;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #7e858f
    }
  }
}
