.upload_doc {
  width: 100%;
  margin-bottom: 64px;
  position: relative;
  .dataName {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobileDataName {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
  .error {
    text-align: center;
    color: red;
    margin-top: 5px;
  }
  .required_error {
    color: red;
    margin-top: 5px;
  }
  > div {
    display: flex;
    flex-direction: column-reverse;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
  }
  .upload_fill_label {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #151724;
      font-family: Inter;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    button {
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      background: #FFFFFF;
      border: 1px solid rgba(21, 23, 36, 0.5);
      box-sizing: border-box;
      border-radius: 6px;
      flex: none;
      order: 1;
      flex-grow: 0;
      padding: 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #151724;
      flex: none;
      flex-grow: 0;
      cursor: pointer;
      &:hover {
          border: 1px solid #151724;
      }
      &:focus {
        border: 2px solid #151724
      }
      &:active {
        border: 1px solid rgba(21, 23, 36, 0.8)
      }
      &:disabled {
          background: #F6F7F8;
          border: 1px solid rgba(21, 23, 36, 0.2);
          color: rgba(21, 23, 36, 0.5);
      }
    }
    @media screen and (max-width: 474px) {
      button {
        top: 40%;
      }
    }
    p {
      font-family: Inter;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #7E858F;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 32px 0px 5px 0;
    }
    @media screen and (max-width: 412px) {
      p {
        margin-top: 40px;
      }
    }
  }
  .file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 16px;
    margin-top: 16px;
    border: 1px solid #eff1f2;
    box-sizing: border-box;
    border-radius: 5px
  }

  .doted_box {
    background: #f6f7f8;
    border: 2px dashed #566171;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    flex-direction: column;
    padding: 24px;
    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #151724;
      margin-bottom: 8px
    }
    > span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #7e858f;
      text-align: center;
      display: block;
      margin-top: 16px
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #151724;
      display: block;
      margin: 0 auto;
      background: #ffffff;
      padding: 12px 10px;
      max-width: 145px;
      margin: 0 auto;
      border: 1px solid rgba(21, 23, 36, 0.5);
      box-sizing: border-box;
      border-radius: 6px;
      > input {
        display: none
      }
    }

  }

  > label {
    margin-top: 16px
  }

  > h4 {
    margin: 40px 0 24px
  }

  > ul li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    list-style-position: inside;
    color: #7e858f
  }
  > ol li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-left: 16px;
    color: #7e858f
  }
}

.error_styles {
  margin-bottom: 34px;
  div {
    div {
      border-color: red !important;
    }
  }
}

.custom_preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  background: #FFFFFF;
  border: 1px solid #EFF1F2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 16px;
  img {
    cursor: pointer;
  }
}
