
.aquiring_info {
    .label {
        display: flex;
        justify-content: space-between;
        >label:first-child {
            width: 65%;
        }
        >label:last-child {
            width: 30%;
        }
    }

    .errorText {
        font-size: 12px;
        color: red;
        margin-bottom: 8px;
    }
    
    .cardholders {
        margin-bottom: 16px;
        > div > label {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 16px;
        }
    }
    @media screen and (max-width: 474px) {
        .cardholders {
            margin-bottom: 16px;
            > div > label {
                grid-column-gap: 4px;
            }
        }
    }

    .transactions  {
        margin-bottom: 16px;
        > div > label {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            > div:nth-child(4) {
                grid-column: span 3;
            }
            > div:nth-child(5) {
                grid-column: span 3;
            }
        }
    }

    @media screen and (max-width: 534px) {
        .transactions {
            > div > label {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .currencies {
      .active {
        border: 1px solid #566171;
      }
        > div {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 16px;
            margin-bottom: 24px;
        }

         button {
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            outline: none;
            padding: 12px 16px 12px 24px;
            color: #151724;
            border: 1px solid #EFF1F2;
            box-sizing: border-box;
            border-radius: 6px;
            height: 48px;

            > img {
                margin-left: 13px
            }
        }
    }

    @media screen and (max-width: 768px) {
        .currencies > div {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 13px;
        }
    }
    @media screen and (max-width: 374px) {
        .currencies > div {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.section_title {
  display: flex;
  margin: 24px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}


.checkbox_container {
    flex-wrap: wrap;
    display: flex;
}

@media screen and (max-width: 526px) {
    .checkbox_container {
        flex-direction: column;
    }
}

.section_title_margin_top {
  display: flex;
  margin: 40px 0 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}

.bottom_line {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  font-size: 16px;
  width: 100%;
  margin-bottom: 49px;
  line-height: 24px;
  padding-bottom: 64px;
  color: #7e858f;
  border-bottom: 1px solid #bcc4cc;
}










