.submitting {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 2px;
    background: #151724;
    z-index: 3000000000;
    animation-name: animate;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-direction: alternate;
}

@keyframes animate {
    0% {width: 0;}
    10% {width: 10%;}
    20% {width: 20%;}
    30% {width: 30%;}
    40% {width: 40%;}
    50% {width: 50%;}
    60% {width: 60%;}
    70% {width: 70%;}
    80% {width: 80%;}
    90% {width: 90%;}
    100% {width: 100%;}
}