.managment {
    margin-bottom: 24px;
    margin-top: 94px;
    form {
      margin-top: 16px;
    }
    > span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        margin: 24px 0 48px;
        color: #7e858f
    }

    .accordion_title_text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        display: inline-block;
        margin-right: 40px;
        color: #7e858f
    }

    > button {
        background: #ffffff;
        border: 1px solid rgba(21, 23, 36, 0.5);
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        outline: none;
        margin-top: 40px;
        padding: 12px 10px;
        color: #151724;
        cursor: pointer;
        &:hover {
            border: 1px solid #151724;
        }
        &:active {
            border: 1px solid rgba(21, 23, 36, 0.8)
        }
        &:disabled {
            background: #F6F7F8;
            border: 1px solid rgba(21, 23, 36, 0.2);
            color: rgba(21, 23, 36, 0.5);
        }
    }

    > button:focus {
        border: 2px solid #151724
    }

    .select_box {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        > div {
            display: flex;
            justify-content: center;
            align-items: center
        }
    }

    .select_box p > span {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: #7e858f;
        padding-left: 20px
    }

    .select_box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 12px 0;
        > div {
            p {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #151724;
                margin: 0 16px;
            }
        }
        .left_side {
            display: flex;
        }
        .right_side {
            display: flex;
        }
    }
    .add_manager {
        display: flex;
        align-items: center;
        .loading_span {
            margin-right: 16px;
        }
    }
}

.display_flex_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 70%;
        margin-right: 16px;
    }
    >div:last-child {
        width: 30%;
        height: 70px;
    }
}

.display_flex_reverse_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 30%;
        margin-right: 16px;
    }
    >div:last-child {
        width: 70%;
        display: block;
    }
}

@media screen and (max-width: 593px) {
    .display_flex_reverse_section {
        flex-direction: column;
        >div:first-child {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
        >div:last-child {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
    }
}

.justify_center_section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 50%;
        margin-right: 16px;
    }
    >div:last-child {
        width: 50%;
    }
}

@media screen and (max-width: 593px) {
    .justify_center_section {
        flex-direction: column;
        >div:first-child {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
        >div:last-child {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
    }
}

.checkbox {
    margin-bottom: 24px;
}

.justify_center_section_person {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    >div:first-child {
        width: 51%;
        margin-right: 16px;
        input {
            padding-left: 10px;
        }
    }
    >div:last-child {
        width: 50%;
        input {
            padding-left: 10px;
        }
    }
}

@media screen and (max-width: 593px) {
    .justify_center_section_person {
        flex-direction: column;
        >div:first-child {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
        >div:last-child {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            input {
                padding-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .display_flex_section {
        flex-direction: column;
        >div:first-child {
            width: 100%;
            margin-right: 0;
            margin-bottom: 16px;
        }
        >div:last-child {
            width: 100%;
            height: 70px;
        }
    }
}

.section_title {
    display: flex;
    margin: 40px 0 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #151724;
}

.some_text_styles {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #7E858F;
}