.textarea_label {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;
    border-bottom: 1px solid rgba(188, 196, 204,0.5);

  .textarea_placeholder {
    position: absolute;
    bottom: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7E858F;
  }
  .textarea_placeholder_not_empty {
    position: absolute;
    top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(86, 97, 113, 0.6);
  }

    textarea {
        resize: vertical;
        display: flex;
        align-items: flex-end;
        border: unset;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #151724;
        outline: none;
    }

   textarea::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #7e858f;
        padding-top: 15px;
    }

    textarea:focus::placeholder {
        color: transparent
    }
}
