.bordered {
    border: 2px solid #151724;
}

.select_label {
    margin-bottom: 16px;
    background: #f6f7f8;
    border-radius: 5px;
    padding: 32px 0 10px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .country_select {
        div {
            border: none;
            background: #f6f7f8;
            box-shadow: unset;
        }
        span {
            display: none;
        }
    }

    > select {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        background: #f6f7f8;
        border: none;
        border-radius: 5px;
        outline: none;
    }

    > select::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #7e858f
    }
}