.shareholders {
    > span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        margin: 24px 0 48px;
        color: #7e858f
    }

    > button {
        background: #ffffff;
        border: 1px solid rgba(21, 23, 36, 0.5);
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        outline: none;
        margin-top: 30px;
        margin-bottom: 120px;
        padding: 12px 10px;
        color: #151724;
        cursor: pointer;
        &:hover {
            border: 1px solid #151724;
        }
        &:active {
            border: 1px solid rgba(21, 23, 36, 0.8)
        }
        &:disabled {
            background: #F6F7F8;
            border: 1px solid rgba(21, 23, 36, 0.2);
            color: rgba(21, 23, 36, 0.5);
        }
    }

    > button:focus {
        border: 2px solid #151724
    }

}