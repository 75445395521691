
.questionnaire {
    margin: 120px 0;
    > h2 {
        margin-bottom: 24px;
    }

    > span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        font-size: 16px;
        width: 100%;
        line-height: 24px;
        color: #7e858f;
        padding-bottom: 48px;
        border-bottom: solid 1px rgba(188, 196, 204, 0.5);
        margin-bottom: 64px;
    }
}