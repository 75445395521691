.declaration {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  margin-top: 120px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #151724;
  h2 {
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid #bcc4cc
  }

  .checkbox_component {
    flex-direction: column;
    .label {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px;
      max-width: 680px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #566171;
      padding-left: 40px;
      padding-bottom: 20px;
      letter-spacing: 0.01px;
      a {
        color: black;
        padding: 0 5px;
      }
    }

    > div > label:first-child {
      padding-right: 10px;
    }
    > button {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      width: 100%;
      height: 62px;
      background: #151724;
      border-radius: 6px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-transform: capitalize;
      color: #ffffff;
      outline: none;
      border: none;
      margin-top: 48px;
      cursor: pointer;
      &:hover {
        background: #151724;
      }
      &:disabled {
        background: rgba(21, 23, 36, 0.5);
        cursor: default;
      }
      &:active {
        background: rgba(21, 23, 36, 0.8);
      }
    }
    
  }
  .loading_span {
    position: absolute;
    left: 40%;
    > div {
      height: 24px !important;
      width: 24px !important;
    }
  }
}

.errormsg {
  color: #FF1744;
  font-size: 13px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0;
}

@media screen and (max-width: 991px) {
  .declaration {
    .checkbox_component {
      > label {
        width: 90%
      }
    }
  }
}

