


    .validator_error_style {
        border-bottom: 1px solid red !important;
    }
    .validator_text {
        color: red;
        font-size: 12px;
    }
    .validator_error_style_radio {
        border: 2px solid red;
    }
    .validator_text_radio {
        color: red;
        font-size: 12px;
        float: right;
        width: 100%;
        flex-basis: 100%;
        margin-top: 8px;
    }