.input {
  margin-bottom: 16px;
}
.select_box {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid #bcc4cc;
  > div {
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #151724;
      margin: 0 16px
    }
  }
  .left_side {
    display: flex;
  }
  .right_side {
    display: flex;
  }
}

.display_flex_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 16px;
  >div:first-child {
    width: 70%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 30%;
    height: 70px;
  }
}

.justify_center_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  >div:first-child {
    width: 50%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 50%;
    height: 70px;
  }
}

.companies_div {
  display: flex;
  flex-direction: column;
  margin: 16px 0 13px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 5px;
  max-height: 312px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
}

.display_flex_reverse_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  >div:first-child {
    width: 30%;
    margin-right: 16px;
  }
  >div:last-child {
    width: 70%;
    height: 70px;
  }
}


@media screen and (max-width: 650px) {
  .display_flex_reverse_section {
    >div:first-child {
      width: 40%;
    }
    >div:last-child {
      width: 60%;
    }
  }
}

@media screen and (max-width: 458px) {
  .display_flex_section {
    >div:first-child {
      width: 50%;
    }
    >div:last-child {
      width: 50%;
    }
  }
  .display_flex_reverse_section {
    >div:first-child {
      width: 50%;
    }
    >div:last-child {
      width: 50%;
    }
  }
}

@media screen and (max-width: 400px) {
  .display_flex_section {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    >div:last-child {
      width: 100%;
    }
  }
  .display_flex_reverse_section {
    flex-direction: column;
    >div:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    >div:last-child {
      width: 100%;
    }
  }
}



.section_title {
  display: flex;
  margin: 24px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #151724;
}
.add_owner {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(21, 23, 36, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  margin: 40px 0px 26px;
  padding: 10px 12px;
  color: #151724;
  cursor: pointer;
  &:hover {
    border: 1px solid #151724;
  }
  &:focus {
    border: 2px solid #151724
  }
  &:active {
    border: 1px solid rgba(21, 23, 36, 0.8)
  }
  &:disabled {
    background: #F6F7F8;
    border: 1px solid rgba(21, 23, 36, 0.2);
    color: rgba(21, 23, 36, 0.5);
  }
  .loading_span {
    margin-right: 16px;
  }
}

.company_button {
  border: none;
  background: transparent;
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
}

.company_button:hover{
  background: #F6F7F8;
}